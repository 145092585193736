import React, { useState, useEffect } from "react";
import MenuLeftPanel from "../../components/MenuLeftPanel/MenuLeftPanel";
import MeinOfPage from "../../components/MeinOfPage/MeinOfPage";
import ModalModel from "../../components/ModalModel/ModalModel";
import "./HomePage.css";
import resetFilters from "../../utilits/resetFilters";
import { useParams } from "react-router-dom";
import modelsDatatest from "../../components/data";

export default function HomePage() {
    const { id } = useParams();
    const [IsLoading, setIsLoading] = useState(false)
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [inputs, setInputs] = useState([
        { title: "Posts", values: { min: "", max: "" } },
        { title: "Price", values: { min: "", max: "" } },
        { title: "Fans", values: { min: "", max: "" } },
        { title: "Streams", values: { min: "", max: "" } },
    ]);
    const [checkboxes, setCheckboxes] = useState([
        { title: "Free view", checked: false },
        { title: "Instagram", checked: false },
        { title: "Snapchat", checked: false },
        { title: "YouTube", checked: false },
        { title: "TikTok", checked: false },
        { title: "Email", checked: false },
        { title: "Telegram", checked: false },
        { title: "Twitter", checked: false },
        { title: "Pinterest", checked: false },
        { title: "Twitch", checked: false },
        { title: "Throne", checked: false },
        { title: "Threads", checked: false },
        { title: "Facebook", checked: false },
        { title: "OFTV", checked: false },
    ]);
    const [uniqueCountries, setUniqueCountries] = useState([])
    const [isVerificationActive, setIsVerificationActive] = useState(false);
    const [isRecentlyActive, setIsRecentlyActive] = useState(false);
    const [selectedSort, setSelectedSort] = useState("Newest");
    const [modelsData, setModelsData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const PageListLenght = 20;
    ////////////////////////получение айди конкретной анкеты по ссылке
    useEffect(() => {
        if (id) {
            fetchModelById(id);
        }
    }, []);
    const fetchModelById = async (id) => {
        setIsLoading(true);
        try {
            const response = await fetch(`getModelById.php?id=${id}`);
            if (response.ok) {
                const data = await response.json();
                if (data) {
                    setSelectedModel(data[0]);
                } else {
                    console.log("Model not found");
                }
            } else {
                console.error("Error fetching model: ", response.status);
            }
        } catch (error) {
            console.error("Error fetching model:", error);
        } finally {
            setIsLoading(false);
        }
    };
    //////////////////////////

    useEffect(() => {
        updateFilteredModels();
    }, []);    
    useEffect(() => {
        updateFilteredModels();
    }, [selectedSort, currentPage]);  

    const handleCountrySelect = (country) => {
        if (country === "ALL") {
            setSelectedCountries(
                selectedCountries.length === uniqueCountries.length
                ? []
                : uniqueCountries.map(item => item.country)
            );
        } else {
            setSelectedCountries((prevSelected) => {
                if (prevSelected.includes(country)) {
                    return prevSelected.filter((item) => item !== country);
                } else {
                    return [...prevSelected, country];
                }
            });
        }
    };    

    const handleChange = (index, field, value) => {
        const onlyNumbers = value.replace(/\D/g, "");
        const newInputs = [...inputs];
        newInputs[index].values[field] = onlyNumbers;
        setInputs(newInputs);
    };

    const handleCheckChange = (index) => {
        const newCheckboxes = [...checkboxes];
        newCheckboxes[index].checked = !newCheckboxes[index].checked;
        setCheckboxes(newCheckboxes);
    };

    const [filtersReset, setFiltersReset] = useState(false);
    const handleResetFilters = () => {
        resetFilters(setSelectedModel, setSelectedCountries, setInputs, setCheckboxes, setIsVerificationActive, setIsRecentlyActive, modelsData);
        setFiltersReset(true);
    };
    useEffect(() => {
        if (filtersReset) {
            updateFilteredModels();
            setCurrentPage(1);
            setFiltersReset(false);
        }
    }, [filtersReset]);

    const updateFilteredModels = async () => { 
        setModelsData([]);
        setIsLoading(false);
        const filters = {
            countries: selectedCountries,
            minPosts: inputs[0].values.min || 0,
            maxPosts: inputs[0].values.max || 9999999,
            minPrice: inputs[1].values.min || 0,
            maxPrice: inputs[1].values.max || 99999999,
            minFans: inputs[2].values.min || 0,
            maxFans: inputs[2].values.max || 99999999,
            minStreams: inputs[3].values.min || 0,
            maxStreams: inputs[3].values.max || 99999999,
            checkboxes: checkboxes.filter(chk => chk.checked).map(chk => chk.title),
            verificationActive: isVerificationActive,
            recentlyActive: isRecentlyActive,
            searchQuery: searchQuery,
            selectedSort: selectedSort,
            limit: PageListLenght,
            offset: (PageListLenght * (currentPage - 1))
        };
    
        try {
            const response = await fetch('getModels.php', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(filters),
            });
    
            const data = await response.json();
            setModelsData(data.models);
            setTotalCount(data.totalCount);
            setUniqueCountries(data.countries);
    
        } catch (error) {
            console.error("Ошибка загрузки данных:", error);
            setTotalCount(200);
            setModelsData(modelsDatatest);
        } finally {
            setIsLoading(true);
        }
    };    

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    }; 
////      все для собильной версии
const [width, setWidth] = useState(window.innerWidth);

useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);    
    window.addEventListener("resize", handleResize);
    setWidth(window.innerWidth);
    return () => window.removeEventListener("resize", handleResize);
}, []);

const [modalFilter, setModalFilter] = useState(false);
////////
    return (
        <div className="HomePage">   
            <>
                { ((width > 768) || modalFilter) && 
                    <MenuLeftPanel
                        selectedCountries={selectedCountries}
                        handleCountrySelect={handleCountrySelect}
                        DataCountry={uniqueCountries}
                        handleChange={handleChange}
                        inputs={inputs}
                        checkboxes={checkboxes}
                        handleCheckChange={handleCheckChange}
                        SelectNewList={updateFilteredModels}
                        setCurrentPage={setCurrentPage}
                        resetFilters={handleResetFilters}
                        setModalFilter={setModalFilter}
                    />
                }
                <MeinOfPage
                    setSelectedModel={setSelectedModel}
                    isVerificationActive={isVerificationActive}
                    setIsVerificationActive={setIsVerificationActive}
                    isRecentlyActive={isRecentlyActive}
                    setIsRecentlyActive={setIsRecentlyActive}
                    selectedSort={selectedSort}
                    setSelectedSort={setSelectedSort}
                    resetFilters={handleResetFilters}
                    modelsData={modelsData}
                    selectedModel={selectedModel}
                    searchQuery={searchQuery}
                    handleSearchChange={handleSearchChange}
                    handleSearchSubmit={updateFilteredModels}     
                    totalCount={totalCount}
                    IsLoading={IsLoading}
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                    modelsPerPage={PageListLenght}
                    modalFilter={modalFilter}
                    setModalFilter={setModalFilter}
                />
                {selectedModel && (
                    <ModalModel
                        model={selectedModel}
                        onClose={() => setSelectedModel(null)}
                    />
                )}
            </> 
        </div>
    );
}