const resetFilters = (setSelectedModel, setSelectedCountries, setInputs, setCheckboxes, setIsVerificationActive, setIsRecentlyActive, modelsData) => {
    setSelectedModel(null);
    setSelectedCountries([]);
    setInputs([
        { title: "Posts", values: { min: "", max: "" } },
        { title: "Price", values: { min: "", max: "" } },
        { title: "Fans", values: { min: "", max: "" } },
        { title: "Streams", values: { min: "", max: "" } },
    ]);
    setCheckboxes([
        { title: "Free view", checked: false },
        { title: "Instagram", checked: false },
        { title: "Snapchat", checked: false },
        { title: "YouTube", checked: false },
        { title: "TikTok", checked: false },
        { title: "E-mail", checked: false },
        { title: "Telegram", checked: false },
        { title: "Twitter", checked: false },
        { title: "Pinterest", checked: false },
        { title: "Twitch", checked: false },
        { title: "Throne", checked: false },
        { title: "Threads", checked: false },
        { title: "Facebook", checked: false },
        { title: "OFTV", checked: false },
    ]);
    setIsVerificationActive(false);
    setIsRecentlyActive(false);
};
export default resetFilters;