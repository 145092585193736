import React, { useEffect, useState } from "react";
import "./ModelsList.css";
import Verification from "../../assets/icons/Verification";
import ImgIcon from "../../assets/icons/ImgIcon";
import IconVideo from "../../assets/icons/IconVideo";
import IconLive from "../../assets/icons/IconLive";
import IconLike from "../../assets/icons/IconLike";
import IconFollowing from "../../assets/icons/IconFollowing";
import DotIcon from "../../assets/icons/DotIcon";
import IconLocation from "../../assets/icons/IconLocation";
import MoreCircle from "../../assets/icons/MoreCircle";
import formatNumber from "../../utilits/formatNumber";
import { useNavigate } from "react-router-dom";
import Pagination from "../Pagination/Pagination";

export default function ModelsList({ onModelClick, modelsData, selectedModel, modelsCount, currentPage, setCurrentPage, modelsPerPage }) {
    const navigate = useNavigate();
    const handleModelClick = async (model) => {
        if (selectedModel === model) {
            onModelClick(null);
            navigate("/");
        } else {
            onModelClick(model);  
            try {
                const response = await fetch("update-views.php", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ modelId: model.id }),
                });
    
                if (!response.ok) {
                    throw new Error("Ошибка обновления просмотров");
                }
            } catch (error) {
                console.error("Ошибка запроса:", error);
            }
            navigate(`/${model.id}`);
        }
    };
    ////      все для собильной версии
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);    
        window.addEventListener("resize", handleResize);
        setWidth(window.innerWidth);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    ////////
    return (
        <div className="ModelsList">
            {modelsData.map((model, index) => (
                <div 
                    key={index} 
                    className={`model-card-content ${selectedModel === model ? "selected" : ""}`}
                    onClick={() => handleModelClick(model)}
                >
                    <div className="model-card"> 
                        <img src={model.photo} alt={model.name} />

                        <div className="description">
                            <div className="name-cont">
                                <p className="name">{model.name}</p>
                                {model.verification && <Verification color="var(--text-color)" />}
                            </div>
                            <div className="text">
                                <p>{model.description}</p>
                                {model.chat && <button>Online chat</button>}
                            </div>
                            {width > 768 ? <SocialValueCont model={model}/> : ""}
                        </div>
                    </div>
                    {width <= 768 ? <SocialValueCont model={model} mobile={true}/> : ""}
                    <div className="location"> 
                        <SocialValue icon={IconLocation} value={model.city} />
                        <DotIcon />
                        <SocialValue value={model.lastLogin} />

                        {width < 768 && <CostInSocialValue model={model}/>}
                    </div> 
                    <div className="svg-menu">
                        <MoreCircle />
                    </div>
                </div>
            ))}

            <Pagination 
                totalModels={modelsCount} 
                currentPage={currentPage} 
                onPageChange={setCurrentPage} 
                modelsPerPage={modelsPerPage}
            />

        </div>
    );
    
}

export const SocialValue = ({ icon: Icon, value }) => (
    <div className="value">
        {Icon && <Icon />}
        {formatNumber(value)}
    </div>
);

export const SocialValueCont = ({model, mobile = false}) => (
    <div className="social-value-cont">
        <div className="social-value">
            <SocialValue icon={ImgIcon} value={model.photosCount} />
            <DotIcon />
            <SocialValue icon={IconVideo} value={model.videosCount} />
            <DotIcon />
            <SocialValue icon={IconLive} value={model.streamsCount} />
            <DotIcon />
            <SocialValue icon={IconLike} value={model.likesCount} />
            <DotIcon />
            <SocialValue icon={IconFollowing} value={model.subscribersCount} />
        </div>
        {!mobile && <CostInSocialValue model={model}/>}
    </div>
)
export const CostInSocialValue = ({model}) => (
    <div className="cost">
        <div>${model.cost}</div> <p>/month</p>
    </div>
)
