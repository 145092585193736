import React, { useState } from "react";
import "./SocialMenu.css";
import ViewButton from "../ViewButton/ViewButton";
import CheckBlock from "../CheckBlock/CheckBlock";

export default function SocialMenu({checkboxes, handleCheckChange}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="SocialMenu">
      {/* Основные чекбоксы */}
      {checkboxes.slice(0, 4).map((checkbox, index) => (
        <CheckBlock
          key={checkbox.title}
          title={checkbox.title}
          checked={checkbox.checked}
          setChecked={() => handleCheckChange(index)}
        />
      ))}

      {/* Дополнительные чекбоксы, которые скрываются */}
      {expanded &&
        checkboxes.slice(4).map((checkbox, index) => (
          <CheckBlock
            key={checkbox.title}
            title={checkbox.title}
            checked={checkbox.checked}
            setChecked={() => handleCheckChange(index + 4)}
          />
        ))}

      <ViewButton setExpanded={setExpanded} expanded={expanded} />
    </div>
  );
}
