import React from "react";
import "./PageMenu.css";
import HomeIcon from "../../assets/icons/HomeIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import WorkIcon from "../../assets/icons/WorkIcon";

export default function PageMenu() {

    return(
        <div className="PageMenu">
            <PageMenuTitle Icon={HomeIcon} title={"Home"}/>
            <PageMenuTitle Icon={SearchIcon} title={"Find Model"} active={true}/>
            <PageMenuTitle Icon={WorkIcon} title={"Working Model"}/>
        </div>
    );
};

const PageMenuTitle = ({ Icon, title, active = false }) => (
    <div className={`PageMenuTitle ${active ? "active" : ""}`}>
        <Icon color={active ? "var(--active-icon-color)" : "var(--default-icon-color)"} />
        <p>{title}</p>
    </div>
);
