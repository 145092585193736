import React from "react";
import "./CheckBlock.css";

const CheckBlock = ({ title,  checked, setChecked}) => {

  return (
    <div className="CheckBlock">
      <div className="title">{title}</div>
      <div 
        className={`switch ${checked ? "checked" : ""}`} 
        onClick={() => setChecked(!checked)}
      >
        <div className="circle"></div>
      </div>
    </div>
  );
};

export default CheckBlock;
