import React, { useState, useRef, useEffect } from "react";
import "./ModalModel.css";
import ViewButton from "../ViewButton/ViewButton";
import Photo1 from "../../assets/images/SocialWeb/Group (1).png";
import Photo2 from "../../assets/images/SocialWeb/Group (2).png";
import Photo3 from "../../assets/images/SocialWeb/Group (3).png";
import Photo4 from "../../assets/images/SocialWeb/Group (4).png";
import Photo5 from "../../assets/images/SocialWeb/Group (5).png";
import Photo6 from "../../assets/images/SocialWeb/Group (6).png";
import Photo7 from "../../assets/images/SocialWeb/Group (7).png";
import Photo8 from "../../assets/images/SocialWeb/Group (8).png";
import Photo9 from "../../assets/images/SocialWeb/Group (9).png";
import Photo10 from "../../assets/images/SocialWeb/Group.png";
import MessageIcon from "../../assets/icons/MessageIcon";
import ViewsIcon from "../../assets/icons/ViewsIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import formatNumber from "../../utilits/formatNumber";
import Verification from "../../assets/icons/Verification";
import { CostInSocialValue, SocialValue, SocialValueCont } from "../ModelsList/ModelsList";
import DotIcon from "../../assets/icons/DotIcon";
import IconLocation from "../../assets/icons/IconLocation";

export default function ModalModel({ model, onClose }) {
  ////      все для собильной версии
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);    
      window.addEventListener("resize", handleResize);
      setWidth(window.innerWidth);
      return () => window.removeEventListener("resize", handleResize);
  }, []);
////////
  const [expanded, setExpanded] = useState(false);
  const [isDescriptionLong, setIsDescriptionLong] = useState(false);
  const descriptionRef = useRef(null);

  const shortDescription = model.description.split("\n").slice(0, 8).join("\n");

  const handleSocialClick = (url) => {
    if (url) window.open(url, "_blank");
  };

  const handleOnlyFansClick = () => {
    window.open(`https://onlyfans.com/${model.id}`, "_blank");
  };

  useEffect(() => {
    if (descriptionRef.current) {
        setIsDescriptionLong(descriptionRef.current.scrollHeight > 180);
    }
  }, [model.description, width]);

  // Automatically load the Yandex share script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://yastatic.net/share2/share.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="ModalModel">
      <div className="views">
        <ViewsIcon />
        <p>{formatNumber(model.views)}</p>
      </div>
      
      {/* Share block visible immediately */}
      <div className="share">
        <div 
          className="ya-share2" 
          data-curtain 
          data-shape="round" 
          data-limit="0" 
          data-size="s"
          data-image={model.photo}
          data-lang="en"
          data-more-button-type="short"
          data-services="telegram,twitter,viber,whatsapp,pinterest,skype,tumblr,evernote,blogger,reddit"
        ></div>
      </div>
      
      <div className="photo-block">
        <img src={model.photo} alt={model.name} />
        <div className="photo-block-name-cont"><p className="photo-block-name">{model.name}</p> {model.verification && <Verification color="var(--text-color)" />}</div>
        { width <= 768 &&

          <div className="photo-block-social-value-cont">
            <SocialValueCont model={model} mobile={true}/>
            <div className="location"> 
              <SocialValue icon={IconLocation} value={model.city} />
              <DotIcon />
              <SocialValue value={model.lastLogin} />

              {width < 768 && <CostInSocialValue model={model}/>}
            </div> 
          </div>

        }
      </div>
      <div className={`description ${expanded && "open"}`} ref={descriptionRef}>
        {expanded ? model.description : shortDescription}
      </div>

      {isDescriptionLong && (
        <ViewButton setExpanded={setExpanded} expanded={expanded} title2="Read More" title1="Read Less" />
      )}

      <div className="social-web">
        {model.Instagram && <SocialWeb img={Photo10} title="Instagram" onClick={() => handleSocialClick(model.Instagram)} />}
        {model.Telegram && <SocialWeb img={Photo1} title="Telegram" onClick={() => handleSocialClick(model.Telegram)} />}
        {model.TikTok && <SocialWeb img={Photo2} title="TikTok" onClick={() => handleSocialClick(model.TikTok)} />}
        {model.FaceBook && <SocialWeb img={Photo3} title="FaceBook" onClick={() => handleSocialClick(model.FaceBook)} />}
        {model.Pinterest && <SocialWeb img={Photo4} title="Pinterest" onClick={() => handleSocialClick(model.Pinterest)} />}
        {model.YouTube && <SocialWeb img={Photo5} title="YouTube" onClick={() => handleSocialClick(model.YouTube)} />}
        {model.Snapchat && <SocialWeb img={Photo6} title="Snapchat" onClick={() => handleSocialClick(model.Snapchat)} />}
        {model.Thread && <SocialWeb img={Photo7} title="Thread" onClick={() => handleSocialClick(model.Thread)} />}
        {model.Twitch && <SocialWeb img={Photo8} title="Twitch" onClick={() => handleSocialClick(model.Twitch)} />}
        {model.Discord && <SocialWeb img={Photo9} title="Discord" onClick={() => handleSocialClick(model.Discord)} />}
      </div>

      <div className="modal-model-footer">
        <button className="modal-model-button" onClick={handleOnlyFansClick}>Open OnlyFans</button>
        { width > 768 && <button className="message-button"><MessageIcon /></button>}
      </div>

      <div className="close-button" onClick={onClose}><CloseIcon /></div>
    </div>
  );
}

const SocialWeb = ({ img, title, onClick }) => (
  <div className="SocialWeb" onClick={onClick}>
    <img src={img} alt={title} />
    <div className="title">{title}</div>
  </div>
);
