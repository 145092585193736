import React, { useState } from "react";
import "./ParamsMenu.css";
import ViewButton from "../ViewButton/ViewButton";

export default function ParamsMenu({handleChange, inputs}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="ParamsMenu">
      {inputs.slice(0, expanded ? inputs.length : 2).map((param, index) => (
        <InputBlock 
          key={param.title} 
          title={param.title} 
          values={param.values} 
          onChange={(field, value) => handleChange(index, field, value)} 
        />
      ))}

      <ViewButton setExpanded={setExpanded} expanded={expanded} />
    </div>
  );
}

const InputBlock = ({ title, values, onChange }) => (
  <div className="InputBlock">
    <div className="title">{title}</div>
    <div className="input-block-cont">
      <input 
        type="text" 
        value={values.min} 
        onChange={(e) => onChange("min", e.target.value)} 
        placeholder="Min"
      />
      <input 
        type="text" 
        value={values.max} 
        onChange={(e) => onChange("max", e.target.value)} 
        placeholder="Max"
      />
    </div>
  </div>
);
