import React from "react";
import "./MeinOfPage.css";
import SearchHeader from "../SearchHeader/SearchHeader";
import ModelsList from "../ModelsList/ModelsList";

export default function MeinOfPage({
    setSelectedModel,
    isVerificationActive,
    setIsVerificationActive,
    isRecentlyActive,
    setIsRecentlyActive,
    selectedSort,
    setSelectedSort,
    resetFilters,
    modelsData,
    selectedModel,
    searchQuery,
    handleSearchChange,
    handleSearchSubmit,
    totalCount,
    IsLoading,
    currentPage, 
    setCurrentPage,
    modelsPerPage,
    modalFilter,
    setModalFilter,
}) {

    return(
        <div className="MeinOfPage">
            <SearchHeader
                isVerificationActive={isVerificationActive}
                setIsVerificationActive={setIsVerificationActive}
                isRecentlyActive={isRecentlyActive}
                setIsRecentlyActive={setIsRecentlyActive}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
                resetFilters={resetFilters}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                handleSearchSubmit={handleSearchSubmit}
                modelsCount={totalCount}
                modalFilter={modalFilter}
                setModalFilter={setModalFilter}
                setCurrentPage={setCurrentPage}
            />
            {!IsLoading ?
                <div className="loading-text">
                    loading...
                </div>
                :
                <ModelsList 
                    onModelClick={setSelectedModel} 
                    modelsData={modelsData}
                    selectedModel={selectedModel}
                    modelsCount={totalCount}
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage} 
                    modelsPerPage={modelsPerPage}
                />
            }
        </div>
    );
};