import React, { useState } from "react";
import "./CountryButton.css";
import ArrowRight from "../../assets/icons/ArrowRight";
import BlueCheckTrue from "../../assets/icons/BlueCheckTrue";
import BlueCheckFalse from "../../assets/icons/BlueCheckFalse";
import ArrowButtom from "../../assets/icons/ArrowButtom";

export default function CountryButton({
  selectedCountries, 
  handleCountrySelect, 
  DataCountry
}) {
  // State to manage the selected countries
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  // Toggle modal visibility
  const toggleModal = () => setModalOpen(!modalOpen);

  // Display "ALL" if no countries are selected or if "ALL" is selected
  const buttonLabel =
    selectedCountries.length === 0
      ? "ALL"
      : selectedCountries.length === DataCountry.length
      ? "ALL"
      : `${selectedCountries.length}`;

  return (
    <div className="CountrySelect">
      {/* Button that shows selected countries */}
      <button className="CountryButton BlueButton" onClick={toggleModal}>
        <div className="title">Country</div>
        <div className="mode">
          {buttonLabel} {!modalOpen ? <ArrowRight color={"var(--blue-button-text-color)"}/> : <ArrowButtom color={"var(--blue-button-text-color)"}/> }
        </div>
      </button>

      {/* Modal that shows the list of countries */}
      {modalOpen && (
        <ModalWindiwCountry
          selectedCountries={selectedCountries}
          handleCountrySelect={handleCountrySelect}
          DataCountry={DataCountry}
        />
      )}
    </div>
  );
}

const ModalWindiwCountry = ({ selectedCountries, handleCountrySelect, DataCountry }) => (
  <div className="ModalWindiwCountry">
    {/* "ALL" option at the start */}
    <TitleCountry
      title="ALL"
      check={selectedCountries.length === DataCountry.length} // Check if all countries are selected
      onClick={() => handleCountrySelect("ALL")} // Handle select/deselect all countries
    />
    
    {/* List of countries */}
    {DataCountry.map((item) => (
      <TitleCountry
        key={item.country}
        title={`${item.country} (${item.value})`}
        check={selectedCountries.includes(item.country)} // Check if country is selected
        onClick={() => handleCountrySelect(item.country)} // Handle click to select or deselect country
      />
    ))}
  </div>
);

const TitleCountry = ({ check = false, title, onClick }) => (
  <div className="TitleCountry" onClick={onClick}>
    {check ? <BlueCheckTrue /> : <BlueCheckFalse />}
    <p className="title">{title}</p>
  </div>
);
