import React, { useRef, useEffect, useState } from "react";
import "./SearchHeader.css";
import SearchIcon from "../../assets/icons/SearchIcon";
import Verification from "../../assets/icons/Verification";
import ViewMore from "../../assets/icons/ViewMore";
import SearchParam from "../../assets/icons/SearchParam";
import SwitchingThemes from "../SwitchingThemes/SwitchingThemes";

export default function SearchHeader({
    isVerificationActive,
    setIsVerificationActive,
    isRecentlyActive,
    setIsRecentlyActive,
    selectedSort,
    setSelectedSort,
    resetFilters,
    searchQuery,
    handleSearchChange,
    handleSearchSubmit,
    modelsCount,
    setCurrentPage,
    modalFilter,
    setModalFilter,
}) {
    const [isSortOpen, setIsSortOpen] = useState(false);
    const sortRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sortRef.current && !sortRef.current.contains(event.target)) {
                setIsSortOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleSortSelect = (sortType) => {
        setSelectedSort(sortType);
        setIsSortOpen(false);
    };
////      все для собильной версии
const [width, setWidth] = useState(window.innerWidth);

useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);    
    window.addEventListener("resize", handleResize);
    setWidth(window.innerWidth);
    return () => window.removeEventListener("resize", handleResize);
}, []);

////////
    return (
        <div className="SearchHeader">
            { width <= 768 && 
                <div className="mobile-menu-title">
                    <SwitchingThemes/>
                    <div className="menu-title">
                        <p className="Donly">Donly</p>
                        <p className="Fans">Fans</p>
                    </div>
                </div>
            }
            <div className="search">
                <input 
                    type="text" 
                    placeholder="Search by description or ..." 
                    value={searchQuery} // Привязываем значение инпута к состоянию
                    onChange={handleSearchChange} // Обработчик изменения
                />
                <div  className={`search-param ${modalFilter ? "rotated" : ""}`} onClick={() => {setModalFilter(prev => !prev); setCurrentPage(1);}}>
                    <SearchParam />
                </div>
                <button className="search-button" onClick={handleSearchSubmit}><SearchIcon /></button>
            </div>
            { width > 768 && 
                <div className="button-container">
                    <div className="left-buttons">
                        <button 
                            className={`Verification ${isVerificationActive ? "active" : ""}`} 
                            onClick={() => setIsVerificationActive(!isVerificationActive)}
                        >
                            <Verification color={isVerificationActive ? "var(--blue-button-text-color)" : "var(--text-color)"}/>
                            Verification
                        </button>
                        <button 
                            className={`Recently ${isRecentlyActive ? "active" : ""}`} 
                            onClick={() => setIsRecentlyActive(!isRecentlyActive)}
                        >
                            Recently online
                        </button>
                    </div>
                    <button className="clear-button" onClick={() => {resetFilters(); setCurrentPage(1);}}>Clear filters</button>
                </div>
            }
            <div className="models-sort">
                <div className="models">
                    <div className="title">Models:</div>
                    <div className="value">{modelsCount}</div>
                </div>
                <div className="sort" ref={sortRef}>
                    <div className="title">Sort:</div>
                    <button className="sort-button" onClick={() => setIsSortOpen(!isSortOpen)}>
                        {selectedSort}
                        <ViewMore color={"var(--text-color)"} />
                    </button>
                    {isSortOpen && (
                        <div className="sort-dropdown">
                            <div className="sort-item" onClick={() => handleSortSelect("Newest")}>
                                Newest
                            </div>
                            <div className="sort-item" onClick={() => handleSortSelect("L to H")}>
                                Price: Low to High
                            </div>
                            <div className="sort-item" onClick={() => handleSortSelect("H to L")}>
                                Price: High to Low
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
