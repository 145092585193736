import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./Pagination.css";

const Pagination = ({ currentPage, totalModels, onPageChange, modelsPerPage }) => {
    const totalPages = Math.ceil(totalModels / modelsPerPage);
    
    if (totalPages <= 1) return null;

    const handlePageChange = (page) => {
        if (page !== currentPage && page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPagination = () => {
        const paginationButtons = [];

        paginationButtons.push(
            <div
                key="prev"
                className={`pagination-arrow ${currentPage === 1 ? "disabled" : ""}`}
                onClick={() => handlePageChange(currentPage - 1)}
            >
                <FaChevronLeft />
            </div>
        );

        paginationButtons.push(
            <div
                key={1}
                className={`pagination-button ${currentPage === 1 ? "active" : ""}`}
                onClick={() => handlePageChange(1)}
            >
                1
            </div>
        );

        if (currentPage > 4) {
            paginationButtons.push(
                <span key="left-dots" className="pagination-button">...</span>
            );
        }

        for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
            paginationButtons.push(
                <div
                    key={i}
                    className={`pagination-button ${currentPage === i ? "active" : ""}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </div>
            );
        }

        if (currentPage < totalPages - 3) {
            paginationButtons.push(
                <span key="right-dots" className="pagination-button">...</span>
            );
        }

        if (totalPages > 1) {
            paginationButtons.push(
                <div
                    key={totalPages}
                    className={`pagination-button ${currentPage === totalPages ? "active" : ""}`}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </div>
            );
        }

        paginationButtons.push(
            <div
                key="next"
                className={`pagination-arrow ${currentPage === totalPages ? "disabled" : ""}`}
                onClick={() => handlePageChange(currentPage + 1)}
            >
                <FaChevronRight />
            </div>
        );

        return paginationButtons;
    };

    return <div className="pagination">{renderPagination()}</div>;
};

export default Pagination;
