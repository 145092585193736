import React from "react";
import "./ViewButton.css";
import ViewMore from "../../assets/icons/ViewMore";
import ViewLess from "../../assets/icons/ViewLess";

const ViewButton = ({ setExpanded, expanded, title1 = "View Less", title2 = "View More" }) => {
  return (
    <button className="view-button" onClick={() => setExpanded(prev => !prev)}>
      {expanded ? <>{title1} <ViewLess/></> : <>{title2} <ViewMore/></>}
    </button>
  );
}

export default ViewButton;
