import React, { useState, useEffect } from "react";
import "./MenuLeftPanel.css";
import PageMenu from "../PageMenu/PageMenu";
import CountryButton from "../CountryButton/CountryButton";
import ParamsMenu from "../ParamsMenu/ParamsMenu";
import SocialMenu from "../SocialMenu/SocialMenu";
import SupportPhoto from "../../assets/images/support.png";
import MoreCircle from "../../assets/icons/MoreCircle";
import SwitchingThemes from "../SwitchingThemes/SwitchingThemes";
import CloseIcon from "../../assets/icons/CloseIcon";

export default function MenuLeftPanel({
    selectedCountries, 
    handleCountrySelect, 
    DataCountry,
    handleChange, 
    inputs,
    checkboxes, 
    handleCheckChange,
    SelectNewList,
    setCurrentPage,
    resetFilters,
    setModalFilter
}) {
    const [supportOnLine, setSupportOnLine] = useState(false);

    useEffect(() => {
        const checkSupportStatus = () => {
            const now = new Date();
            const utcHours = now.getUTCHours();
            const moscowHours = utcHours + 3; // МСК = UTC+3

            setSupportOnLine(moscowHours >= 10 && moscowHours < 20);
        };

        checkSupportStatus();
        const interval = setInterval(checkSupportStatus, 60000); // Проверять каждую минуту

        return () => clearInterval(interval);
    }, []);

    const handleSupportClick = () => {
        window.open("https://t.me/username", "_blank");
    };
////      все для собильной версии
const [width, setWidth] = useState(window.innerWidth);

useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);    
    window.addEventListener("resize", handleResize);
    setWidth(window.innerWidth);
    return () => window.removeEventListener("resize", handleResize);
}, []);
////////
    return (
        <div className="MenuLeftPanel">
            {width <= 768 &&
                <div className="close-button" onClick={() => setModalFilter(prev => !prev)}>
                    <CloseIcon />
                </div>
            }
            {width <= 768 &&
                <div className="mobile-bottom-buttons">
                    <button className="footer-menu-button" onClick={() => {resetFilters(); setCurrentPage(1);}}>
                        Clear filters
                    </button>
                    <button 
                        className="footer-menu-button BlueButton" 
                        onClick={() => { 
                            SelectNewList(); 
                            setCurrentPage(1);
                            setModalFilter(prev => !prev); 
                        }}
                    >
                        Find model
                    </button>
                </div>
            }
            <div className="menu-left-panel-cont">
                {width > 768 && <>
                    <SwitchingThemes/>
                    <div className="menu-title">
                        <p className="Donly">Donly</p>
                        <p className="Fans">Fans</p>
                    </div>
                </>}
                {width > 768 &&
                    <PageMenu /> 
                }
                <CountryButton 
                    selectedCountries={selectedCountries} 
                    handleCountrySelect={handleCountrySelect} 
                    DataCountry={DataCountry}
                />
                <ParamsMenu 
                    handleChange={handleChange} 
                    inputs={inputs}
                />
                <SocialMenu 
                    checkboxes={checkboxes} 
                    handleCheckChange={handleCheckChange} 
                />
            </div>
            <div className="footer-menu">
                {width > 768 &&
                    <button className="footer-menu-button BlueButton" onClick={() => {SelectNewList(); setCurrentPage(1);}}>
                        Find model
                    </button>
                }

                <div className="support-block" onClick={handleSupportClick} style={{ cursor: "pointer" }}>
                    <div className="support-photo">
                        <img src={SupportPhoto} alt="support-photo" />
                        <div className={`support-photo-circle ${supportOnLine ? "online" : ""}`}></div>
                    </div>
                    <div className="support-description">
                        <p className="title">
                            Alexis Wolen <MoreCircle/>
                        </p>
                        <p className="text">
                            Support {supportOnLine ? "(Online)" : "(Offline)"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
