const baseModel = {
    name: "Johnathan 'TechGuru' Smith",
    description: "Johnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgetsJohnathan is a well-known tech enthusiast who dives deep into the latest gadgets",
    photosCount: 95,
    videosCount: 30,
    streamsCount: 5,
    likesCount: 2800,
    subscribersCount: 12000,
    lastLogin: "2025-02-06",
    city: "New York",
    country: "United States",
    cost: 6,
    verification: true,
    photo: "https://avatars.mds.yandex.net/i?id=08e45900ce0a69b74c6499d335b1342f_l-5263630-images-thumbs&n=13",
    chat: false,
    "Free view": false,
    Instagram: true,
    Telegram: false,
    TikTok: false,
    FaceBook: true,
    Pinterest: false,
    YouTube: true,
    Snapchat: false,
    Thread: true,
    Twitch: true,
    Discord: false,
    "E-mail": "techguru.smith@example.com",
    views: 100,
};

// Создаём массив из 200 копий с уникальными ID
const modelsDatatest = Array.from({ length: 10 }, (_, i) => ({
    ...baseModel,
    id: i + 1, // Добавляем уникальный идентификатор
    name: `Johnathan 'TechGuru' Smith #${i + 1}`, // Делаем имя уникальным
    views: baseModel.views + i * 10, // Можем изменить просмотры для разнообразия
}));

export default modelsDatatest;
